.cand-box {
    background-color: #fff;
    position: relative;
    width: 92%;
    box-shadow: 2px 2px 3px #e8b2b2;
    margin-left: 2.5rem;
    min-height: 145px;
    margin-bottom: 1.5rem;
    display: block;
   
}

.cand-box figure {
    width: 120px;
    height:90px;
    /* background-color: aquamarine; */
    border-radius: 50%;
    display: block;
    margin-bottom: 0;
    overflow: hidden;
    border: 1px solid #ffffff;
   
    text-align: center;
    position: absolute;
    top: 0;
    padding-right: 5;
    bottom: 0;
    margin: auto;
    left: -2.5rem;
    z-index: 2;
    box-shadow: 1px 1px 1px #e36e6e;
}
.diHover :hover{
    text-shadow: aqua;
background-color: yellow;
color: red;

}